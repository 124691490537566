// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB_ZzxVfztmAZFeEPQXbbU6Jpz-kn25reI",
  authDomain: "rajdhani-signstore-database.firebaseapp.com",
  projectId: "rajdhani-signstore-database",
  storageBucket: "rajdhani-signstore-database.appspot.com",
  messagingSenderId: "236110248353",
  appId: "1:236110248353:web:7bde27c4c4c54ec91033e0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };



