import { db } from '../../config/config';
import { collection, getDocs, query, orderBy } from "firebase/firestore";

const fetchData = async () => {
  const itemsCollection = collection(db, 'products');
  const itemsQuery = query(itemsCollection, orderBy('batchNumber'));
  const itemsSnapshot = await getDocs(itemsQuery);
  
  const items = [];
  
  itemsSnapshot.forEach(doc => {
    const batchData = doc.data();
    if (batchData.documents?.length) {
      const batchProducts = batchData.documents.map(product => ({
        id: product.id,
        img: product.mainImage,
        title: product.title,
        description: product.description
      }));
      items.push(...batchProducts);
    }
  });
  
  return items;
};

const dataItem = await fetchData();
export default dataItem;