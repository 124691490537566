import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { getUpdatedMenus } from "../../pages/menu";
import logo1 from "../../assets/images/logo/logo.png";
import logo2 from "../../assets/images/logo/logo2.png";
import "./styles.scss";

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentLogo, setCurrentLogo] = useState('logo1');
  const [opacity, setOpacity] = useState(1);
  const [menuItems, setMenuItems] = useState([]);
  const headerRef = useRef(null);
  const menuTimeoutRef = useRef(null);

  useEffect(() => {
    const loadMenus = async () => {
      try {
        const updatedMenus = await getUpdatedMenus();
        setMenuItems(updatedMenus);
      } catch (error) {
        console.error("Error loading menus:", error);
      }
    };
    
    loadMenus();
  }, []);

  useEffect(() => {
    const header = headerRef.current;
    const observerOptions = {
      root: null,
      rootMargin: "-300px 0px 0px 0px",
      threshold: [0, 1]
    };

    const observerCallback = (entries) => {
      const [entry] = entries;
      setIsFixed(!entry.isIntersecting);
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    if (header) {
      observer.observe(header);
    }

    return () => {
      if (header) {
        observer.unobserve(header);
      }
    };
  }, []);

  useEffect(() => {
    const animationSequence = [
      { logo: 'logo1', duration: 5000 },
      { logo: 'text', duration: 1700 },
      { logo: 'logo2', duration: 3000 },
    ];
    let currentIndex = 0;
    let timeout;

    const runAnimation = () => {
      const { logo, duration } = animationSequence[currentIndex];
      setOpacity(0);
      
      setTimeout(() => {
        setCurrentLogo(logo);
        setOpacity(1);
      }, 200);

      currentIndex = (currentIndex + 1) % animationSequence.length;
      timeout = setTimeout(runAnimation, duration);
    };

    runAnimation();
    return () => clearTimeout(timeout);
  }, []);

  const handleMenuActive = useCallback(() => {
    setMenuActive(prev => !prev);
    setActiveIndex(null);

    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current);
    }

    if (!menuActive) {
      menuTimeoutRef.current = setTimeout(() => {
        setMenuActive(false);
      }, 2500);
    }
  }, [menuActive]);

  const handleDropdown = useCallback((index) => {
    setActiveIndex(prev => prev === index ? null : index);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu-item")) {
        setActiveIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const resetInactivityTimer = useCallback(() => {
    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current);
    }
    menuTimeoutRef.current = setTimeout(() => {
      setMenuActive(false);
    }, 2500);
  }, []);

  const styles = {
    menuItem: {
      color: 'black',
      position: 'relative',
      cursor: 'pointer'
    },
    menuLink: {
      color: 'black',
      textDecoration: 'none',
      display: 'block',
      padding: '10px 15px',
      transition: 'color 0.3s ease'
    },
    subMenuContainer: {
      position: 'absolute',
      top: '100%',
      left: '0',
      backgroundColor: 'white',
      boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
      borderRadius: '8px',
      minWidth: '220px',
      zIndex: 1000,
      opacity: 0,
      visibility: 'hidden',
      transition: 'all 0.3s ease',
      pointerEvents: 'none',
      padding: '4px',
      border: '1px solid rgba(0,0,0,0.05)'
    },
    subMenuContainerActive: {
      visibility: 'visible',
      opacity: 1,
      pointerEvents: 'auto',
      transform: 'translateY(5px)'
    },
    subMenu: {
      listStyle: 'none',
      padding: '2px 0',
      margin: 0,
      maxHeight: '400px',
      overflowY: 'auto'
    },
    subMenuItem: {
      fontSize: '0.8rem',
      fontWeight: '400',
      padding: '4px 12px',
      margin: '1px 0',
      borderRadius: '6px',
      transition: 'all 0.2s ease',
      color: '#4a5568',
      display: 'block',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '1.4',
      maxWidth: '100%'
    },
    header: {
      backgroundColor: 'white',
      transition: 'all 0.3s ease'
    },
    headerFixed: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 1000,
      backgroundColor: 'white',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
    },
    logoContainer: {
      width: '200px',
      height: '60px',
      overflow: 'hidden',
      position: 'relative'
    },
    logo: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      transition: "opacity 200ms ease-in-out"
    },
    contactButton: {
      color: 'white',
      padding: '10px 20px',
      borderRadius: '5px',
      textDecoration: 'none',
      transition: 'background-color 0.3s ease'
    }
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .menu-item {
        position: relative;
      }
      
      .menu-item:hover > div {
        visibility: visible !important;
        opacity: 1 !important;
        pointer-events: auto !important;
        transform: translateY(5px) !important;
      }
      
      .menu-item-has-children:hover > .menu-link {
        color: #3498db;
      }
      
      .menu-link:hover {
        color: #3498db !important;
      }
      
      .sub-menu-item {
        position: relative;
        transition: all 0.2s ease;
        font-weight: 400 !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        line-height: 1.4 !important;
        padding: 4px 12px !important;
        margin: 1px 0 !important;
      }
      
      .sub-menu-item:hover {
        background-color: #f7fafc;
        color: #2d3748 !important;
        padding-left: 16px !important;
      }
      
      .sub-menu-item:active {
        background-color: #edf2f7;
      }
      
      .sub-menu {
        scrollbar-width: thin;
        scrollbar-color: #e2e8f0 #f7fafc;
      }
      
      .sub-menu::-webkit-scrollbar {
        width: 4px;
      }
      
      .sub-menu::-webkit-scrollbar-track {
        background: #f7fafc;
        border-radius: 4px;
      }
      
      .sub-menu::-webkit-scrollbar-thumb {
        background: #e2e8f0;
        border-radius: 4px;
      }
      
      .sub-menu::-webkit-scrollbar-thumb:hover {
        background: #cbd5e0;
      }
      
      .contact-button:hover {
        background-color: #2980b9;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const renderMenuItems = useCallback((items, parentIndex = null) => {
    return items.map((data, idx) => {
      const currentIndex = parentIndex !== null ? `${parentIndex}-${idx}` : `${idx}`;
      const isActive = activeIndex === currentIndex;
      const isProductsMenu = idx === 2; // Assuming Products is the third menu item (index 2)

      return (
        <li
          key={currentIndex}
          onMouseEnter={() => handleDropdown(currentIndex)}
          onMouseLeave={() => setActiveIndex(null)}
          className={`menu-item ${data.namesub ? "menu-item-has-children" : ""}`}
          style={styles.menuItem}
        >
          <Link 
            to={data.links} 
            className="menu-link"
            style={styles.menuLink}
            onClick={(e) => {
              if (data.namesub) {
                e.preventDefault();
                handleDropdown(currentIndex);
              }
            }}
          >
            {data.name || data.sub}
          </Link>
          {data.namesub && (
            <div 
              style={{
                ...styles.subMenuContainer,
                ...(isActive ? styles.subMenuContainerActive : {})
              }}
            >
              <ul style={styles.subMenu}>
                {data.namesub.map((subItem, subIdx) => (
                  <li key={`${currentIndex}-${subIdx}`}>
                    <Link
                      to={isProductsMenu ? "/collection" : subItem.links}
                      className="sub-menu-item"
                      style={styles.subMenuItem}
                      title={subItem.sub || subItem.name}
                    >
                      {subItem.sub || subItem.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      );
    });
  }, [activeIndex, handleDropdown]);

  const renderLogo = () => {
    const logoStyle = {
      ...styles.logo,
      opacity: opacity
    };

    let content;
    if (currentLogo === 'logo1') {
      content = <img style={logoStyle} src={logo1} alt="Logo 1" />;
    } else if (currentLogo === 'logo2') {
      content = <img style={logoStyle} src={logo2} alt="Logo 2" />;
    } else {
      content = (
        <div 
          style={{
            ...logoStyle,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "24px",
            fontWeight: "bold",
            color: "black"
          }}
        >
          OUR BRANDS
        </div>
      );
    }

    return (
      <div style={styles.logoContainer}>
        {content}
      </div>
    );
  };

  return (
    <header
      ref={headerRef}
      id="header_main"
      className={`header ${isFixed ? "is-fixed" : ""}`}
      style={{
        ...styles.header,
        ...(isFixed ? styles.headerFixed : {})
      }}
    >
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="header__logo">
                <Link to="/">
                  {renderLogo()}
                </Link>
              </div>

              <div className="header__right" style={{ flex: 1 }}>
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                  style={{ backgroundColor: 'white' }}
                  onMouseEnter={resetInactivityTimer}
                >
                  <ul id="menu-primary-menu" className="menu" style={{ height: '2vh', color: 'black' }}>
                    {renderMenuItems(menuItems)}
                  </ul>
                </nav>
                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                  style={{ color: 'black' }}
                >
                  <span style={{ backgroundColor: 'black' }}></span>
                </div>
              </div>

              <div className="header__action">
                <Link 
                  to="/contact" 
                  className="action-btn"
                  style={styles.contactButton}
                >
                  <span>Contact</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;