import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/config";

// Utility function to build hierarchical menu structure
const buildTagHierarchy = (tags, parentId = null) => {
  return tags
    .filter(tag => tag.parentId === parentId)
    .map(tag => ({
      id: tag.id,
      sub: tag.name,
      links: `/${tag.name.toLowerCase().replace(/\s+/g, '-')}`,
      children: buildTagHierarchy(tags, tag.id)
    }))
    .sort((a, b) => a.sub.localeCompare(b.sub));
};

// Function to convert hierarchical tags to flat namesub array
const flattenTagHierarchy = (hierarchy) => {
  return hierarchy.reduce((acc, item) => {
    const flatItem = {
      id: item.id,
      sub: item.sub,
      links: item.links
    };
    
    acc.push(flatItem);
    if (item.children && item.children.length > 0) {
      acc.push(...flattenTagHierarchy(item.children));
    }
    
    return acc;
  }, []);
};

// Function to fetch tags and update menu structure
export const getUpdatedMenus = async () => {
  try {
    const tagsSnapshot = await getDocs(collection(db, "tags"));
    const tags = [];
    tagsSnapshot.forEach(doc => {
      const data = doc.data();
      if (data.documents && Array.isArray(data.documents)) {
        tags.push(...data.documents);
      } else {
        tags.push({ id: doc.id, ...data });
      }
    });

    const tagHierarchy = buildTagHierarchy(tags);
    const flattenedTags = flattenTagHierarchy(tagHierarchy);

    const menus = [
      {
        id: 1,
        name: 'Home',
        links: '/',
      },
      {
        id: 2,
        name: 'About',
        links: '/about',
      },
      {
        id: 3,
        name: 'Products',
        links: '/collection',
        namesub: flattenedTags
      },
      {
        id: 4,
        name: 'Collection',
        links: '',
        namesub: [
          {
            id: 1,
            sub: 'Indoor',
            links: '/Indoor',
          },
          {
            id: 2,
            sub: 'Outdoor',
            links:'/outdoor'
          },
          {
            id: 3,
            sub: 'FinePitch',
            links:'/fp'
          },
          {
            id: 4,
            sub: 'Flexible Module',
            links:'/Fm'
          },
          {
            id: 5,
            sub: 'Aluminum Bottom Shell',
            links:'/Abs'
          },
          {
            id: 6,
            sub: 'Indoor Monochrome',
            links:'/IM'
          },
        ],
      },
      {
        id: 6,
        name: 'Contact',
        links: '/contact',
      },
      {
        id: 7,
        name: 'Terms',
        links: '/Terms',
      },
      {
        id: 8,
        name: 'Privacy',
        links: '/Privacy-Policy',
      },
    ];

    return menus;
  } catch (error) {
    console.error("Error fetching tags for menu:", error);
    return defaultMenus; // Return static menu structure as fallback
  }
};

// Default static menu structure as fallback
const defaultMenus = [
  {
    id: 1,
    name: 'Home',
    links: '/',
  },
  {
    id: 2,
    name: 'About',
    links: '/about',
  },
  {
    id: 3,
    name: 'Products',
    links: '/collection',
  },
  {
    id: 4,
    name: 'Collection',
    links: '',
    namesub: [
      {
        id: 1,
        sub: 'Indoor',
        links: '/Indoor',
      },
      {
        id: 2,
        sub: 'Outdoor',
        links:'/outdoor'
      },
      {
        id: 3,
        sub: 'FinePitch',
        links:'/fp'
      },
      {
        id: 4,
        sub: 'Flexible Module',
        links:'/Fm'
      },
      {
        id: 5,
        sub: 'Aluminum Bottom Shell',
        links:'/Abs'
      },
      {
        id: 6,
        sub: 'Indoor Monochrome',
        links:'/IM'
      },
    ],
  },
  {
    id: 6,
    name: 'Contact',
    links: '/contact',
  },
  {
    id: 7,
    name: 'Terms',
    links: '/Terms',
  },
  {
    id: 8,
    name: 'Privacy',
    links: '/Privacy-Policy',
  },
];

export default defaultMenus;