import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ArrowUpRight } from 'lucide-react';

// Animation and layout constants
const CONSTANTS = {
  SCROLL_SPEED: 1,
  SCROLL_INTERVAL: 16,
  CARD_WIDTH: 280,
  CARD_GAP: 16,
  RESET_THRESHOLD: 1
};

const ProductCard = React.memo(({ product, onProductClick }) => (
  <div className="relative flex-none w-[280px]">
    <div className="relative h-[320px] bg-white rounded-xl shadow-sm">
      <div className="relative w-full h-[180px] overflow-hidden rounded-t-xl">
        <img
          src={product.mainImage}
          alt={product.title}
          className="h-full w-full object-cover"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-transparent to-transparent" />
      </div>
      
      <div className="p-4 flex flex-col h-[140px]">
        <h3 className="text-base font-semibold text-gray-900 line-clamp-1 mb-2">
          {product.title}
        </h3>
        
        <div className="mt-auto">
          <Link
            to={`/product/${product.id}`}
            onClick={onProductClick}
            className="inline-flex items-center justify-center w-full px-4 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 rounded-lg hover:from-purple-700 hover:to-indigo-700"
          >
            View Details
            <ArrowUpRight size={16} className="ml-1.5" />
          </Link>
        </div>
      </div>
    </div>
  </div>
));

const RelatedProductsCarousel = ({ products, currentProduct }) => {
  const containerRef = useRef(null);
  const [scrollX, setScrollX] = useState(0);
  const animationRef = useRef(null);
  const cardsRef = useRef([]);

  const relatedProducts = useMemo(() => {
    if (!currentProduct?.tags || !products?.length) return [];
    
    const filtered = products
      .filter(product => 
        product.id !== currentProduct.id && 
        product.tags?.some(tag => currentProduct.tags.includes(tag))
      )
      .sort((a, b) => {
        const aMatchCount = a.tags?.filter(tag => currentProduct.tags.includes(tag)).length || 0;
        const bMatchCount = b.tags?.filter(tag => currentProduct.tags.includes(tag)).length || 0;
        return bMatchCount - aMatchCount;
      });

    // Duplicate products if we have less than needed to create infinite scroll effect
    return filtered.length < 4 ? [...filtered, ...filtered, ...filtered] : [...filtered, ...filtered];
  }, [products, currentProduct]);

  const resetPosition = useCallback(() => {
    if (!containerRef.current) return;
    
    const totalWidth = relatedProducts.length * (CONSTANTS.CARD_WIDTH + CONSTANTS.CARD_GAP);
    const halfWidth = totalWidth / 2;
    
    if (scrollX <= -halfWidth) {
      setScrollX(scrollX + halfWidth);
      containerRef.current.style.transform = `translateX(${scrollX + halfWidth}px)`;
    }
  }, [scrollX, relatedProducts.length]);

  const animate = useCallback(() => {
    setScrollX(prev => prev - CONSTANTS.SCROLL_SPEED);
    if (containerRef.current) {
      containerRef.current.style.transform = `translateX(${scrollX - CONSTANTS.SCROLL_SPEED}px)`;
    }
    resetPosition();
    animationRef.current = requestAnimationFrame(animate);
  }, [scrollX, resetPosition]);

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [animate]);

  const handleProductClick = useCallback(() => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  }, []);

  if (!relatedProducts.length) return null;

  return (
    <div className="w-full pt-16 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 relative">
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900">Related Products</h2>
        </div>

        <div className="relative">
          <div
            ref={containerRef}
            className="flex gap-4"
            style={{
              willChange: 'transform',
            }}
          >
            {relatedProducts.map((product, index) => (
              <ProductCard
                key={`${product.id}-${index}`}
                product={product}
                onProductClick={handleProductClick}
                ref={el => cardsRef.current[index] = el}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    mainImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  onProductClick: PropTypes.func
};

RelatedProductsCarousel.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    mainImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string)
  })).isRequired,
  currentProduct: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default RelatedProductsCarousel;